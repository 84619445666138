import { CSSTransition } from "react-transition-group";
import useMeasure from "react-use-measure";

export const HeightTransition = ({children, expanded}) => {
  const [ref, bounds] = useMeasure();
  return (
    <CSSTransition
      in={expanded}
      timeout={1000}
      classNames="fade-height"
      unmountOnExit
    >
      <div style={{ "--auto-height": bounds.height + "px" }}>
        <div ref={ref}>{children}</div>
      </div>
    </CSSTransition>
  );
};
