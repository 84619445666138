import { useCallback, useEffect, useId, useRef } from "react"
import useMeasure from "react-use-measure"
import { isEqual, uniq, uniqBy } from "lodash"
import SVG from "react-inlinesvg"
import { cleanFamilies } from "../utils/articles"
import classNames from "classnames"
import { LinkedFamilyName } from "./LinkedFamilyName"

// react component replacement for select
interface IProps {
  label: string
  onChange: (value: string[]) => void
  value: string[]
  options: string[][]
  expanded: boolean
}

/**\
 * some odd requirements here:
 * - update from outside: should scroll to active option
 *   to make this work, maybe can check if the option is in the viewport
 * - clicking arrow: should scroll to active option
 * - scrolling: no scroling! let the browser do it
 */
export const FamilySelector = ({ label, options, value, onChange, expanded, ...props }: IProps) => {
  const id = useId()
  const scrollAreaRef = useRef<HTMLDivElement>(null)
  const [ref, bounds] = useMeasure()

  const cleanedValue = cleanFamilies(value)
  const cleanedOptions = uniqBy([
    ...options,
    cleanedValue, // if coming from full utility, value might be unincluded in options
  ], JSON.stringify)

  const update = (v: IProps["value"]) => {
    /** @todo v is sometimes undefined? */
    if (v && !isEqual(v, cleanedValue)) {
      onChange(v)
    }
  }

  // useref for if the user scrolled recently
  const recentlyScrolled = useRef(false)
  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.currentTarget
    const scrollLeft = target.scrollLeft
    const i = Math.round(scrollLeft / bounds.width)
    update(cleanedOptions[i])

    recentlyScrolled.current = true
  }
  const step = (dir: number) => {
    const index = cleanedOptions.findIndex((option) => isEqual(option, cleanedValue))
    const nextIndex = (index + dir + cleanedOptions.length) % cleanedOptions.length
    if (expanded) {
      update([cleanedOptions[nextIndex][0]])
    } else {
      scrollToIndex(nextIndex)
    }
  }
  const scrollToIndex = useCallback(
    (index: number) => {
      const left = index * bounds.width
      if (scrollAreaRef.current) {
        scrollAreaRef.current.scroll({
          left,
        })
      }
    },
    [bounds]
  )

  // todo: select is scrolling to window top on initial click for some reason

  // hook to see if the user has scrolled recently
  useEffect(() => {
    if (!expanded && !recentlyScrolled.current) {
      const index = cleanedOptions.findIndex((o) => isEqual(o, cleanedValue))
      scrollToIndex(index)
    }
    recentlyScrolled.current = false
  }, [cleanedValue, cleanedOptions, scrollToIndex])

  return (
    <div className={classNames("FamilySelector", { expanded })}>
      {label && <div className="label">{label}</div>}
      <div className="FamilySelector__input-container">
        {expanded ? (
          <div className="select">
            <select
              name=""
              id=""
              value={cleanedValue[0]}
              onChange={(e) => {
                update([e.target?.value])
              }}
            >
              {cleanedOptions.map((familyList) => (
                <option key={familyList[0]} value={familyList[0]}>
                  {familyList[0]}
                </option>
              ))}
            </select>
            <div className="arrow">
              <SVG src="/icons/left.svg" role="presentation" />
            </div>
          </div>
        ) : (
          <>
            <div className="FamilySelector__input-scroll" ref={scrollAreaRef} onScroll={handleScroll}>
              <div className="FamilySelector__options" ref={ref}>
                {cleanedOptions.map((familyList) => (
                  <label key={familyList.toString()}>
                    <input
                      type="radio"
                      name={`family-selector-${id}`}
                      value={familyList}
                      onChange={(e) => update([e.target?.value])}
                      defaultChecked={isEqual(familyList, value)}
                    />
                    {familyList.map((family, familyIndex) => (
                      <LinkedFamilyName family={family} key={family + familyIndex} />
                    ))}
                  </label>
                ))}
              </div>
            </div>
            <div className="buttons">
              <button onClick={() => step(-1)} aria-label="Select previous family">
                <SVG src="/icons/left.svg" role="presentation" />
              </button>
              <button onClick={() => step(1)} aria-label="Select next family">
                <SVG src="/icons/right.svg" role="presentation" />
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
