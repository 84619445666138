export const formatDate = (input: string) => {
  if (input) {
    return new Date(input).toLocaleDateString(undefined, {
      weekday: undefined,
      year: "numeric",
      month: "long",
      day: "numeric",
    })
  } else {
    return input
  }
}