import classNames from "classnames"
import React, { useEffect, useState, useRef } from "react"
import { FamilySelector } from "../components/FamilySelector"
import { Button } from "./Button"
import { NumericInput } from "./NumericInput"
import { getAvailablePairingsForPrimary, getAvailablePrimaryFamilies, NormalizedPairing } from "../utils/articles"
import { meta } from "*.mdx"
import { displayFamilies, textFamilies } from "../data/utilityFontList"
import CSSPrintout from "./CSSPrintout"
import { HeightTransition } from "./HeightTransition"
import { isEqual, uniq } from "lodash"
import useSWR from "swr"
import SVG from "react-inlinesvg"

export const fetcher = (...args) => fetch(...args).then((res) => res.json())

const Utility = ({
  article,
  currentPairing,
  setSecondaries,
  setBody,
  utilityAnchorBounds,
  updateOverrides,
}: {
  article: typeof meta
  currentPairing: NormalizedPairing
  setSecondaries: (secondaries: string[] | []) => void
  setBody: (body: string, updateSecondaries?: boolean) => void
  updateOverrides: (overrides: object) => void
  utilityAnchorBounds: { top: number; height: number } | undefined
}) => {
  const [isScrollingUp, setIsScrollingUp] = useState(false)
  const [cartStyles, setCartStyles] = useState<string[]>([])
  const [expanded, setExpanded] = useState(false)
  const bodyOptions = expanded ? textFamilies.map((el) => [el]) : getAvailablePrimaryFamilies(article.pairings).map((v) => [v.family])
  const body = currentPairing.primary.family
  const secondaries = expanded
    ? displayFamilies.map((el) => [el])
    : getAvailablePairingsForPrimary(article?.pairings, body).map((e) => [e.secondary.family, e.tertiary?.family].filter(Boolean))
  const hasTertiary = currentPairing.tertiary?.family
  const prevPosition = useRef(0)
  const [scales, setScales] = useState({ primary: 16, secondary: 28 })
  const fonts = useRef({
    primary: currentPairing.primary.family,
    secondary: currentPairing.secondary.family,
    tertiary: currentPairing.tertiary?.family,
  })
  const pairingKeys = ["primary", "secondary", currentPairing.tertiary ? "tertiary" : undefined].filter(Boolean)

  const params = new URLSearchParams()
  cartStyles.forEach((font) => params.append("families", font))
  const url = cartStyles.length ? `/api/cart?${params.toString()}` : undefined
  const { data: cartData, error } = useSWR(url, fetcher)

  const udpdateScales = () => {
    const htmlEl = document.documentElement
    const primaryEl = document.querySelector(".body p")
    const secondaryEl = document.querySelector(".article-title")
    const remPx = parseFloat(getComputedStyle(htmlEl).fontSize)
    const primaryPx = parseFloat(getComputedStyle(primaryEl).getPropertyValue("--font-size")) * remPx
    const secondaryPx = parseFloat(getComputedStyle(secondaryEl).getPropertyValue("--font-size")) * remPx
    setScales({ primary: primaryPx, secondary: secondaryPx })
  }
  useEffect(() => {
    if (expanded) {
      udpdateScales()
      window.addEventListener("resize", udpdateScales)
    } else {
      window.removeEventListener("resize", udpdateScales)
    }
    return () => {
      window.removeEventListener("resize", udpdateScales)
    }
  }, [expanded])

  let visible = Boolean(utilityAnchorBounds?.top)
  let top = expanded ? 0 : utilityAnchorBounds?.top + "px"
  let height = expanded ? "100%" : utilityAnchorBounds?.height + "px"

  // when pairing is updated, make sure the utility is visible
  useEffect(() => {
    setIsScrollingUp(true)
  }, [currentPairing])
  // handle visibility during scrolling
  useEffect(() => {
    const handleScroll = (e) => {
      const deltaY = window.scrollY - prevPosition.current
      if (Math.abs(deltaY) > 3) {
        const isScrollingUp = deltaY < 0
        setIsScrollingUp(isScrollingUp)
      }
      prevPosition.current = window.scrollY
    }
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [prevPosition])

  const getOverride = (group: "primary" | "secondary" | "tertiary", key: string, defaultValue: number) => {
    return currentPairing[group].overrides[key] ?? defaultValue
  }
  const [cssPrintout, setCSSPrintout] = useState("")

  // CSS Printout: generates output from `article.cssSelectors`
  // Checkout: sets desired type styles
  useEffect(() => {
    // if (expanded) {
    const familyUpdated = pairingKeys.some((i) => currentPairing?.[i].family !== fonts.current?.[i])

    const runUpdate = () => {
      let newCartStyles: string[] = []
      let newCSS: string[] = []

      article.cssSelectors
        .map((selectors) => {
          const [selector, pseudoSelector] = selectors.selector.split("::")
          const element = document.querySelector(selector)
          return {
            element,
            selector,
            output: selectors.output,
            pseudoSelector: pseudoSelector ? `:${pseudoSelector}` : undefined,
            excludeFromCSS: selectors.excludeFromCSS ?? false,
          }
        })
        .filter((selectors) => Boolean(selectors.element))
        .map((selector) => {
          const style = window.getComputedStyle(selector.element!, selector.pseudoSelector)
          let properties = {
            "font-family": style.getPropertyValue("font-family"),
            "font-size": style.getPropertyValue("font-size"),
            "font-weight": style.getPropertyValue("font-weight"),
            "font-style": style.getPropertyValue("font-style"),
            "line-height": style.getPropertyValue("line-height"),
            "letter-spacing": style.getPropertyValue("letter-spacing"),
          }
          properties["font-size"] = `${parseFloat(properties["font-size"]).toFixed(1)}px`
          properties["line-height"] = `${(parseFloat(properties["line-height"]) / parseFloat(properties["font-size"])).toFixed(2)}`
          properties["letter-spacing"] = `${+((parseFloat(properties["letter-spacing"]) || 0) / parseFloat(properties["font-size"])).toFixed(3)}em`
          const declarations = Object.entries(properties).map(([key, value]) => `${key}: ${value};`)

          newCartStyles.push(
            `${properties["font-family"].replace(/\"/g, "")} ${properties["font-weight"]}${properties["font-style"] === "italic" ? " italic" : ""}`
          )
          if (!selector.excludeFromCSS) {
            newCSS.push(`${selector.output} {\n  ${declarations.join("\n  ")} \n}`)
          }
        })

      setCSSPrintout(newCSS.join("\n"))
      newCartStyles = uniq(newCartStyles)
      if (!isEqual(newCartStyles, cartStyles)) {
        setCartStyles(newCartStyles)
      }
    }
    if (familyUpdated) {
      window.setTimeout(() => {
        document.fonts.ready.then(function () {
          runUpdate()
        })
      }, 251)
    } else {
      runUpdate()
    }
    // }
  })
  return (
    <div
      id="utility"
      className="utility-wrap"
      style={
        {
          "--visibility": visible ? "visible" : "hidden",
          "--top": top,
          "--height": height,
        } as any
      }
    >
      <div className={classNames("Utility", { isScrollingUp, expanded })}>
        {article && (
          <>
            <div>
              <FamilySelector
                label={hasTertiary && !expanded ? "Headline and Secondary Text" : "Headline"}
                options={secondaries as string[][]}
                expanded={expanded}
                value={
                  expanded
                    ? [currentPairing.secondary.family]
                    : ([currentPairing.secondary.family, currentPairing.tertiary?.family].filter(Boolean) as string[])
                }
                onChange={(v) => {
                  if (expanded) {
                    updateOverrides({
                      secondary: { family: v[0] },
                    })
                  } else {
                    setSecondaries(v)
                  }
                }}
              />
              <HeightTransition expanded={expanded}>
                <div className="controls-wrapper">
                  <NumericInput
                    shortLabel="Size"
                    label="Headline Size"
                    value={getOverride("secondary", "size", 1)}
                    steps={{ default: 1, fine: 0.1 }}
                    format={(v) => `${v.toFixed(1)}px`}
                    min={8}
                    scale={scales.secondary}
                    onChange={(v) => {
                      updateOverrides({
                        secondary: { overrides: { size: v } },
                      })
                    }}
                  />
                  <NumericInput
                    shortLabel="Height"
                    label="Line Height"
                    value={getOverride("secondary", "leading", 1)}
                    steps={{ fine: 1, default: 5, coarse: 10 }}
                    scale={100}
                    min={50}
                    format={(v) => `${v.toFixed(0)}%`}
                    onChange={(v) => {
                      updateOverrides({
                        secondary: {
                          overrides: { leading: v },
                        },
                      })
                    }}
                  />
                  <NumericInput
                    shortLabel="Spacing"
                    label="Letter Spacing"
                    value={getOverride("secondary", "tracking", 0)}
                    scale={100}
                    min={-20}
                    max={50}
                    steps={{ fine: 0.1 }}
                    format={(v) => `${v.toFixed(1)}%`}
                    onChange={(v) => {
                      updateOverrides({
                        secondary: {
                          overrides: { tracking: v },
                        },
                      })
                    }}
                  />
                </div>
              </HeightTransition>
            </div>
            <HeightTransition expanded={expanded}>
              <div className="spacer" />
            </HeightTransition>
            <HeightTransition expanded={!expanded}>
              <div className="spacer half" />
            </HeightTransition>
            <div>
              <FamilySelector
                label="Body Text"
                options={bodyOptions}
                expanded={expanded}
                value={[body]}
                onChange={(v) => {
                  expanded
                    ? updateOverrides({
                        primary: { family: v[0] },
                      })
                    : setBody(v, !expanded)
                }}
              />
              <HeightTransition expanded={expanded}>
                <div className="controls-wrapper">
                  <NumericInput
                    shortLabel="Size"
                    label="Text Size"
                    value={getOverride("primary", "size", 1)}
                    steps={{ default: 1, fine: 0.1 }}
                    format={(v) => `${v.toFixed(1)}px`}
                    min={8}
                    scale={scales.primary}
                    onChange={(v) => {
                      updateOverrides({
                        primary: { overrides: { size: v } },
                      })
                    }}
                  />
                  <NumericInput
                    shortLabel="Height"
                    label="Line Height"
                    value={getOverride("primary", "leading", 1)}
                    min={50}
                    steps={{ fine: 1, default: 5, coarse: 10 }}
                    format={(v) => `${v.toFixed(0)}%`}
                    scale={100}
                    onChange={(v) => {
                      updateOverrides({
                        primary: {
                          overrides: { leading: v },
                        },
                      })
                    }}
                  />
                  <NumericInput
                    shortLabel="Spacing"
                    label="Letter Spacing"
                    value={getOverride("primary", "tracking", 0)}
                    scale={100}
                    min={-20}
                    max={50}
                    steps={{ fine: 0.1 }}
                    format={(v) => `${v.toFixed(1)}%`}
                    onChange={(v) => {
                      updateOverrides({
                        primary: {
                          overrides: { tracking: v },
                        },
                      })
                    }}
                  />
                </div>
              </HeightTransition>
              <HeightTransition expanded={expanded}>
                {currentPairing.tertiary && (
                  <div className="controls-wrapper">
                    <div className="spacer" />
                    <FamilySelector
                      label="Secondary Text"
                      options={textFamilies.map((el) => [el])}
                      expanded={expanded}
                      value={[currentPairing.tertiary.family]}
                      onChange={(v) => {
                        updateOverrides({
                          tertiary: { family: v[0] },
                        })
                      }}
                    />
                    <NumericInput
                      shortLabel="Size"
                      label="Text Size"
                      value={getOverride("tertiary", "size", 1)}
                      steps={{ default: 1, fine: 0.1 }}
                      format={(v) => `${v.toFixed(1)}px`}
                      min={8}
                      scale={scales.primary}
                      onChange={(v) => {
                        updateOverrides({
                          tertiary: { overrides: { size: v } },
                        })
                      }}
                    />
                    <NumericInput
                      shortLabel="Height"
                      label="Line Height"
                      value={getOverride("tertiary", "leading", 1)}
                      min={50}
                      steps={{ fine: 1, default: 5, coarse: 10 }}
                      format={(v) => `${v.toFixed(0)}%`}
                      scale={100}
                      onChange={(v) => {
                        updateOverrides({
                          tertiary: {
                            overrides: { leading: v },
                          },
                        })
                      }}
                    />
                    <NumericInput
                      shortLabel="Spacing"
                      label="Letter Spacing"
                      value={getOverride("tertiary", "tracking", 0)}
                      scale={100}
                      min={-20}
                      max={50}
                      steps={{ fine: 0.1 }}
                      format={(v) => `${v.toFixed(1)}%`}
                      onChange={(v) => {
                        updateOverrides({
                          tertiary: {
                            overrides: { tracking: v },
                          },
                        })
                      }}
                    />
                  </div>
                )}
              </HeightTransition>
              <div className="desktop-cart-button">
                <div className="spacer third" />
                <div className="controls-wrapper lightly-padded centered">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className={classNames("Button", { loading: !cartData })}
                    aria-disabled={!cartData}
                    href={"https://commercialtype.com/cart/styles/" + (cartData ? cartData.map((el) => el.id).join(",") : "")}
                  >
                    Add To Cart
                    <SVG src="/icons/external.svg" role="presentation" />
                  </a>
                </div>
                <div className="spacer" />
              </div>
              <div className="mobile-cart-button">
                <HeightTransition expanded={expanded}>
                  <div className="spacer third" />
                  
                  <div className="controls-wrapper lightly-padded centered">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      className={classNames("Button", { loading: !cartData })}
                      aria-disabled={!cartData}
                      href={"https://commercialtype.com/cart/styles/" + (cartData ? cartData.map((el) => el.id).join(",") : "")}
                    >
                      Add To Cart
                      <SVG src="/icons/external.svg" role="presentation" />
                    </a>
                  </div>
                  <div className="spacer half" />
                </HeightTransition>
              </div>
              <HeightTransition expanded={expanded}>
                <CSSPrintout value={cssPrintout} label={"Copy CSS"} successLabel={"CSS copied"} />
                <div className="spacer"></div>
              </HeightTransition>
            </div>
          </>
        )}
        <div className="utility-toggle-container">
          <button
            className="utility-toggle"
            aria-expanded={expanded}
            aria-controls="utility"
            onClick={() => {
              setExpanded(!expanded)
            }}
          >
            {expanded ? "Collapse" : "More Options"}
            <div className="arrow">
              <SVG src="/icons/right.svg" role="presentation" />
            </div>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Utility
