import {
  createContext,
  Dispatch,
  SetStateAction
} from "react";
import { getPairingCSS } from "./articles"

interface MDXHelpersInterface {
  overrides: ReturnType<typeof getPairingCSS>;
  utilityAnchorElement: any;
  setUtilityAnchorBounds: any;
}

export const MDXHelpers = createContext<MDXHelpersInterface | null>(null);
