
export const Video = (
  props: React.HTMLAttributes<HTMLVideoElement> & {
    filename: string;
    alt: string;
  }
) => {
  const { filename, alt, ...videoProps } = props;
  return (
    <div className="media">
      <video
        width="250"
        height="250"
        muted
        autoPlay
        loop
        playsInline
        {...videoProps}
        aria-label={alt}
      >
        {["webm", "mp4"].map((ext) => (
          <source key={ext} src={`/videos/${filename}.${ext}`} />
        ))}
      </video>
    </div>
  );
};
