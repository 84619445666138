import classNames from "classnames"
import { useEffect, useRef, useState, useContext, ReactNode } from "react"
import useMeasure from "react-use-measure"
import { MDXHelpers } from "../utils/MDXHelpersInterface"

export const FontSection = (props: { children: ReactNode }) => {
  const helpers = useContext(MDXHelpers)
  const [animatedOverrides, setAnimatedOverrides] = useState(helpers?.overrides)
  const [fontsReady, setFontsReady] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)
  const [ref, { height }] = useMeasure()
  useEffect(() => {
    document.fonts.ready.then(function () {
      setFontsReady(true)
      setFirstLoad(false)
    })
  }, [])
  useEffect(() => {
    // transition
    const familyUpdated = [1, 2, 3].some(i => helpers?.overrides[`--family-${i}`] !== animatedOverrides[`--family-${i}`])
    if (familyUpdated) {
      setFontsReady(false)
      window.setTimeout(() => {
        document.fonts.ready.then(function () {
          setFontsReady(true)
          setAnimatedOverrides(helpers?.overrides)
        })
      }, 250)
    } else {
      setAnimatedOverrides(helpers?.overrides)
    }
  }, [helpers?.overrides])
  
  const loadingFonts = !fontsReady

  return (
    <div
      className="FontSection"
      onMouseEnter={(e: any) => {
        const block = e.target.closest(".FontSection") || e.target
        if (helpers) {
          helpers.utilityAnchorElement.current = block
        }
        const computedStyle = window.getComputedStyle(block)
        if (block) {
          // get elements paddingTop and height
          helpers?.setUtilityAnchorBounds({
            top: block.offsetTop,
            height: block.offsetHeight,
          })
        }
      }}
    >
      <div className="posrel" ref={ref}>
        <div
          className={classNames("transitioner", { loadingFonts })}
          style={animatedOverrides}
        >
          {props.children}
        </div>
      </div>
      {(loadingFonts && !firstLoad) && <div className="preloader" aria-hidden style={helpers?.overrides}>
        {props.children}
      </div>}
    </div>
  )
}
