import classNames from "classnames"
import { useRouter } from "next/router"
import { nbsp } from "../utils/html"
import { Cover } from "../components/Cover"
import { useEffect, useRef, useState } from "react"
import Utility from "../components/Utility"
import { formatDate } from "../utils/date"
import { articles } from "../data/articles"
import { Transition, TransitionGroup, CSSTransition } from "react-transition-group"
import { findPairing, generateNormalizedPairings, getPairingAtIndex, getPairingCSS, NormalizedPairing } from "../utils/articles"
import Header from "../components/Header"
import Link from "next/link"
import { Image } from "../components/Image"
import { Video } from "../components/Video"
import { FontSection } from "../components/FontSection"
import { MDXHelpers } from "../utils/MDXHelpersInterface"
import { Metadata } from "../components/Metadata"
import { merge } from "lodash"

import { useIntersectionObserver } from "usehooks-ts"

const Article = ({ articleSlug }: { articleSlug: string }) => {
  const router = useRouter()
  const slug = router.query.articleSlug || articleSlug

  const article = articles.find((article) => {
    return article.meta.slug === slug
  })

  const footerRef = useRef<HTMLDivElement | null>(null)
  const entry = useIntersectionObserver(footerRef, {})
  const isFooterVisible = !!entry?.isIntersecting

  // const anchorElement = useRef<Element>(null)
  // const anchorElementViewportPos = useRef(0)
  const utilityAnchorElement = useRef<Element>(null)
  const [utilityAnchorBounds, setUtilityAnchorBounds] = useState<{
    top?: number
    height?: number
  }>({})
  const [randomIndex, setRandomIndex] = useState(0)
  const [currentPairing, setCurrentPairing] = useState<NormalizedPairing>(getPairingAtIndex(article?.meta.pairings, randomIndex))
  const [overrides, setOverrides] = useState(getPairingCSS(article?.meta.weights, currentPairing))

  const randomize = () => {
    setRandomIndex(randomIndex + 1)
  }
  useEffect(() => {
    // set pairing when index changes
    const newPairing = getPairingAtIndex(article?.meta.pairings, randomIndex)
    if (newPairing) {
      setCurrentPairing(newPairing)
      setOverrides(getPairingCSS(article?.meta.weights, newPairing))
    }
  }, [randomIndex, article?.meta.pairings, article?.meta.weights])

  // useLayoutEffect(() => {
  //   // at [x50%, y25%]
  //   let yPercent = 0.25
  //   anchorElement.current = document?.elementFromPoint(
  //     window.innerWidth / 2,
  //     window.innerHeight * yPercent
  //   )
  //   // measure the element's offset from the top of the page
  //   if (anchorElement) {
  //     // get scroll position of anchorElement
  //     console.log(anchorElement.current)
  //     anchorElementViewportPos.current =
  //       anchorElement.current?.getBoundingClientRect().top || 0
  //     console.log("useEffect")
  //     console.log(
  //       "anchorElementViewportPos: " + anchorElementViewportPos.current
  //     )
  //   }
  // }, [article?.pairingCSS])
  // useEffect(() => {
  //   // scroll to the anchorElement + the original offset
  //   const desiredScrollPosition =
  //     document.documentElement.scrollTop +
  //     anchorElement.current.getBoundingClientRect().top -
  //     anchorElementViewportPos.current
  //   window.scrollTo(0, desiredScrollPosition)
  //   console.log("scroll pos", desiredScrollPosition)
  //   console.log("after")
  //   console.log("rect: " + anchorElement.current.getBoundingClientRect().top)
  // }, [article?.pairingCSS])

  // const anchorElement = useRef<Element | null>(null)
  // const anchorElementViewportPos = useRef(0)
  // useLayoutEffect(() => {
  //   // at [x50%, y25%]
  //   let yPercent = 0.25
  //   anchorElement.current = document?.elementFromPoint(
  //     window.innerWidth / 2,
  //     window.innerHeight * yPercent
  //   )
  //   // measure the element's offset from the top of the page
  //   if (anchorElement) {
  //     // get scroll position of anchorElement
  //     console.log(anchorElement.current)
  //     anchorElementViewportPos.current =
  //       anchorElement.current?.getBoundingClientRect().top || 0
  //     console.log("useEffect")
  //     console.log(
  //       "anchorElementViewportPos: " + anchorElementViewportPos.current
  //     )
  //   }
  // }, [article?.pairingCSS])
  // useEffect(() => {
  //   // scroll to the anchorElement + the original offset
  //   const desiredScrollPosition =
  //     document.documentElement.scrollTop +
  //     anchorElement.current.getBoundingClientRect().top -
  //     anchorElementViewportPos.current
  //   window.scrollTo(0, desiredScrollPosition)
  //   console.log("scroll pos", desiredScrollPosition)
  //   console.log("after")
  //   console.log("rect: " + anchorElement.current.getBoundingClientRect().top)
  // }, [article?.pairingCSS])

  const setSecondaries = (secondaries: string[]) => {
    const newPairing = findPairing(article?.meta.pairings, [currentPairing.primary.family, ...secondaries])
    if (newPairing) {
      setCurrentPairing(newPairing)
      setOverrides(getPairingCSS(article?.meta.weights, newPairing))
    }
  }
  const setBody = (body: string[], updateSecondaries?: boolean) => {
    const v = body[0]
    const newPairing = generateNormalizedPairings(article?.meta.pairings).find((pairing) => pairing.primary.family === v)
    if (newPairing) {
      setCurrentPairing(newPairing)
      setOverrides(getPairingCSS(article?.meta.weights, newPairing))
    }
  }

  return (
    article && (
      <>
        <MDXHelpers.Provider
          value={{
            overrides,
            utilityAnchorElement,
            setUtilityAnchorBounds,
          }}
          key={slug.toString()}
        >
          <Header onRandomize={randomize}></Header>
          <div
            className={classNames(
              "Article",
              `paragraph-${article?.meta.paragraphs}`,
              article?.meta.pulled_quotes ? "pulled-quotes" : "aligned-quotes",
              article?.meta.type,
              `cover-position-${article?.meta.cover?.position}`,
              { isFooterVisible }
            )}
          >
            <Utility
              article={article.meta}
              utilityAnchorBounds={utilityAnchorBounds}
              currentPairing={currentPairing}
              setSecondaries={setSecondaries}
              updateOverrides={(overrides) => {
                const newPairing = merge(currentPairing, overrides)
                const newPairingCSS = getPairingCSS(article?.meta.weights, newPairing)
                setOverrides(newPairingCSS)
              }}
              setBody={(v) => setBody(v, true)}
            />
            <header>
              <Cover src={article?.meta.cover?.src} type={article?.meta.cover?.type} alt={article?.meta.cover?.alt} credit={article?.meta.cover?.credit} />
              <div className="article-header-text">
                <FontSection>
                  <h1 className="article-title block">{nbsp(article?.meta?.title)}</h1>
                </FontSection>
                <FontSection>
                  <div className="byline block">
                    <div className="author">By {nbsp(article?.meta.author)}</div>
                    {article?.meta.date && <time className="date">{formatDate(article?.meta.date)}</time>}
                  </div>
                </FontSection>
                <FontSection>{article.meta.credit && <p className="credit">{nbsp(article.meta.credit)}</p>}</FontSection>
              </div>
            </header>
            <div className="body">
              <article.component
                components={{
                  Video,
                  Image,
                  FontSection: FontSection,
                }}
              ></article.component>
            </div>
            <footer ref={footerRef}>
              <div className="container">
                <nav>
                  <FontSection family={0}>
                    <ul className="footer-articles">
                      {articles
                        .filter((a) => a.meta.slug !== slug)
                        .map((article, i) => (
                          <li key={article.meta.slug}>
                            <Link href={`/${article.meta.slug}`}>
                              <a className="footer-card">
                                <h2 className="title">{nbsp(article.meta.title)}</h2>
                                <p className="author">by {nbsp(article.meta.author)}</p>
                              </a>
                            </Link>
                          </li>
                        ))}
                    </ul>
                  </FontSection>
                </nav>
              </div>
            </footer>
          </div>
          <Metadata articleMetadata={article?.meta} />
        </MDXHelpers.Provider>
      </>
    )
  )
}

export async function getStaticProps(ctx) {
  const isArticle = articles.find((article) => {
    return article.meta.slug === ctx.params.articleSlug
  })

  if (isArticle) {
    return { props: { articleSlug: ctx.params.articleSlug } }
  } else {
    return { notFound: true }
  }
}
export async function getStaticPaths() {
  return {
    paths: articles.map((article) => {
      return {
        params: {
          articleSlug: article.meta.slug,
        },
      }
    }),
    fallback: true, // false or 'blocking'
  }
}

export default Article
