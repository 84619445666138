/** allowed families in the full utility menu */
export const displayFamilies = [
  "Action Condensed Light",
  "Action Condensed Regular",
  "Action Condensed Medium",
  "Action Condensed Bold",
  "Action Text Bright",
  "Action Text Dark",
  "Algebra",
  "Algebra Display",
  "Antique No 6",
  "Atlas Grotesk",
  "Austin",
  "Austin News Deck",
  "Austin News Text",
  "Austin Text",
  "Brunel Deck",
  "Brunel Text Short",
  "Canela Deck",
  "Caponi Display",
  "Caponi Slab",
  "Caponi Text",
  "Caslon Doric",
  "Caslon Ionic",
  "Chiswick Deck",
  "Chiswick Grotesque",
  "Chiswick Headline",
  "Chiswick Sans",
  "Chiswick Sans Text",
  "Chiswick Text",
  "Darby Sans",
  "Darby Sans Poster",
  "Darby Serif Display",
  "Darby Serif Text",
  "Duplicate Ionic",
  "Duplicate Sans",
  "Duplicate Slab",
  "Eugenio Sans",
  "Eugenio Sans Text",
  "Eugenio Serif",
  "Feature Deck",
  "Feature Text",
  "Frame Head",
  "Frame Text",
  "Graphik",
  "Graphik Compact",
  "Graphik Condensed",
  "Graphik X Condensed",
  "Guardian Egyptian Headline",
  "Guardian Egyptian Text",
  "Guardian Sans Headline",
  "Guardian Sans Headline Condensed",
  "Guardian Sans Text",
  "Le Jeune Deck",
  "Le Jeune Text",
  "Lyon Display",
  "Lyon Text",
  "Marr Sans",
  "Marr Sans Condensed",
  "Orientation",
  "Portrait",
  "Portrait Text",
  "Produkt",
  "Publico Headline",
  "Publico Text",
  "Review",
  "Review Condensed",
  "Review Poster",
  "Robinson",
  "Sanomat",
  "Sanomat Sans",
  "Sanomat Sans Text",
  "Styrene A",
  "Styrene B",
  "Terza Editor",
  "Terza Reader",
]

export const textFamilies = [
  "Action Text Bright",
  "Action Text Dark",
  "Algebra",
  "Antique No 6",
  "Atlas Grotesk",
  "Austin News Text",
  "Austin Text",
  "Brunel Text Short",
  "Caponi Text",
  "Caslon Doric",
  "Caslon Ionic",
  "Chiswick Grotesque",
  "Chiswick Sans Text",
  "Chiswick Text",
  "Darby Sans",
  "Darby Serif Text",
  "Duplicate Ionic",
  "Duplicate Sans",
  "Duplicate Slab",
  "Eugenio Sans Text",
  "Feature Text",
  "Frame Text",
  "Graphik",
  "Graphik Compact",
  "Guardian Egyptian Text",
  "Guardian Sans Text",
  "Le Jeune Text",
  "Lyon Text",
  "Marr Sans",
  "Portrait Text",
  "Produkt",
  "Publico Text",
  "Review",
  "Review Condensed",
  "Robinson",
  "Sanomat Sans Text",
  "Styrene A",
  "Styrene B",
  "Terza Editor",
  "Terza Reader",
]
