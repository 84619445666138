import { flatten, keyBy, mapValues, uniq, uniqBy } from "lodash"
import { toOxfordComma } from "./string"
import { meta } from "src/content/toc.mdx"
type MetadataPairing = typeof meta.pairings
type Pairing = {
  family: string
  style?: string
  weight?: string
}
type OverrideKey = "size" | "leading" | "style" | "weight" | "tracking"
type Override = { key: OverrideKey; value: string | number | undefined }
type NormalizedPairing2 = [string, string] | [string, string, string]
type Unpacked<T> = T extends (infer U)[] ? U : T

const mod = (a: number, b: any[]) => {
  return a % b.length
}
export const getPairingString = (pairing: NormalizedPairing2) => {
  let arr = [...new Set(pairing)]
  return toOxfordComma(arr)
}
export const getDefaultPairing = (pairings: MetadataPairing) => {
  if (!pairings) return []
  let key = Object.keys(pairings)[0]
  const pairing = { [key]: pairings[key] }
  return getPairing(pairing)
}

export const overrideRegexes: {
  regex: RegExp
  key: OverrideKey
  type: "number" | "string"
}[] = [
  {
    regex: /\<(\d+\.?\d*)\>/,
    key: "size",
    type: "number",
  },
  {
    regex: /\{(\d+\.?\d*)\}/,
    key: "leading",
    type: "number",
  },
  {
    regex: /\((\S+)\)/,
    key: "style",
    type: "string",
  },
  {
    regex: /\[(\d+)\]/,
    key: "weight",
    type: "number",
  },
  {
    regex: /\|(\d+)\|/,
    key: "tracking",
    type: "number",
  },
]
export const parseOverrides = (family: string) => {
  const overrides = overrideRegexes
    .map(({ regex, key, type }) => {
      // null default value for to allow null overriding other values in lodash.merge
      let value: string | number | null = family.match(regex)?.[1] ?? null
      if (type === "number" && value) {
        value = parseFloat(value)
      }
      return { key, value }
    })
  return mapValues(keyBy(overrides, "key"), "value")
}

// removes override syntax from an array of family definitions
export const cleanFamilies = (family: string[]) => {
  return family.map((f) => cleanFamily(f))
}

// removes override syntax from family definition
export const cleanFamily = (family: string) => {
  let temp = family
  overrideRegexes.forEach(({ regex }) => {
    temp = temp.replace(regex, "")
  })
  return temp.trim()
}

// generates pairing and override CSS (mostly in custom properties)
export const getPairingCSS = (
  inWeights: typeof meta.weights,
  pairing: NormalizedPairing
) => {
  if (!pairing) return {}
  const weights =
    Object.entries(inWeights || {}).reduce((style, keyValue, i) => {
      ;(style as any)[`--weight-${keyValue[0]}`] = keyValue[1] ?? ""
      return style
    }, {}) || {}

  // generates object of css custom properties for the three pairings
  const pairingCSS = [
    pairing.primary,
    pairing.secondary,
    pairing.tertiary,
  ].reduce((style: { [index: string]: string }, pairingComponent, i) => {
    if (pairingComponent) {
      Object.entries(pairingComponent?.overrides).forEach(([key, value]) => {
        style[`--${key}-${i + 1}`] = value ? `${value}` : ""
      })
      style[`--family-${i + 1}`] = pairingComponent?.family ? `"${pairingComponent?.family}"` : ""
    }
    return style
  }, {})

  return { ...weights, ...pairingCSS }
}

// gets first pairing
export const getPairing = (pairings: MetadataPairing) => {
  return generateNormalizedPairings(pairings)[0]
}

// gets randomized pairing at pseudo-random index
// this seems ineffecient when we have getNormalizedPairings,
// but secondary lists aren't always the same length
export const getPairingAtIndex = (pairings: MetadataPairing, index: number) => {
  if (!pairings) return []
  const bodyIndex = mod(index, Object.keys(pairings))
  const secondaries = Object.values(pairings)[bodyIndex]
  const secondaryIndex = mod(
    Math.floor(index / Object.keys(pairings).length),
    secondaries
  )
  return generateNormalizedPairings(pairings).find(
    (pairing) =>
      pairing.primaryIndex === bodyIndex &&
      pairing.secondaryIndex === secondaryIndex
  )!
}

export const generateNormalizedPairings = (pairings: MetadataPairing) => {
  const entries = Object.entries(pairings)
  const generateFamilyData = (family: string) => ({
    family: cleanFamily(family),
    overrides: parseOverrides(family),
  })

  return flatten(
    entries.map(([primary, secondaryAndOptionalTertiary], primaryIndex) => {
      return secondaryAndOptionalTertiary.map((secondaries, secondaryIndex) => {
        let obj = {
          primaryIndex,
          secondaryIndex,
          primary: generateFamilyData(primary),
          secondary: generateFamilyData(
            Array.isArray(secondaries) ? secondaries[0] : secondaries
          ),
          tertiary: Array.isArray(secondaries)
            ? generateFamilyData(secondaries[1])
            : undefined,
        }

        return obj
      })
    })
  )
}
export type NormalizedPairings = ReturnType<typeof generateNormalizedPairings>
export type NormalizedPairing = Unpacked<NormalizedPairings>
export type NormalizedPairingFamily = NormalizedPairing["primary"]

// grabs all primary families from normalized pairings
export const getAvailablePrimaryFamilies = (pairings: MetadataPairing) => {
  return uniqBy(
    generateNormalizedPairings(pairings).map((pairing) => pairing.primary),
    "family"
  )
}

// finds a specific pairing data item by `[primary, secondary[, and tertiary]]`
export const findPairing = (
  pairings: MetadataPairing,
  pairing: [string, string] | [string, string, string]
) => {
  return generateNormalizedPairings(pairings).find(
    (p) =>
      p.primary.family === pairing[0] &&
      p.secondary.family === pairing[1] &&
      (p.tertiary ? pairing[2] : true)
  )
}

// gets normalized pairings for a given primary
export const getAvailablePairingsForPrimary = (
  pairings: MetadataPairing,
  primary: string
) => {
  return generateNormalizedPairings(pairings).filter(
    (pairing) => pairing.primary.family === primary
  )
}
