import classNames from "classnames"
import { merge } from "lodash"
import React, { useState, useRef, useId, FocusEventHandler, useEffect } from "react"

export const NumericInput = ({
  disabled,
  shortLabel,
  label,
  value,
  min,
  max,
  steps,
  step,
  scale = 1,
  unit,
  format = (v) => `${v}`,
  onChange,
}: {
  disabled?: boolean
  
  shortLabel?: string
  label?: string

  value: number

  min?: number
  max?: number
  steps: {
    /** used with shift key */
    coarse?: number
    /** used in scrubbing */
    fine?: number
    /** used in stepper */
    default?: number
  }
  format: (v: number) => string
  step?: number
  scale?: number
  unit?: string

  onChange: (value: number) => void
}) => {
  const id = useId()
  const [isActive, setIsActive] = useState(false)

  const containerElement = useRef<any>(null)
  const inputRef = useRef<HTMLElement>(null)

  // prettier-ignore
  steps = merge({
    coarse: 10,
    fine: 1,
    default: 1,
  }, steps)

  const scaleValue = (value: number) => {
    return value * scale
  }
  const unscaleValue = (value: number) => {
    return value / scale
  }

  const scaledValue = scaleValue(value)
  const currentValue = useRef(scaledValue)
  const [inputValue, setInputValue] = useState(format(scaledValue))

  useEffect(() => {
    currentValue.current = scaledValue
    setInputValue(format(scaledValue))
  }, [scaledValue, format])
  const didMove = useRef(false)

  const handleFocus: FocusEventHandler<HTMLInputElement> = (e) => {
    e.target?.select()
  }

  const onMouseDown = (e: MouseEvent) => {
    e.preventDefault()

    const isRightClick = e.button === 2 || e.ctrlKey

    if (!isRightClick) {
      ;(e.target as any).requestPointerLock()

      setIsActive(true)

      document.addEventListener("mousemove", onDrag)
      document.addEventListener("mouseup", onMouseUp)
      document.addEventListener("touchmove", onDrag)
      document.addEventListener("touchend", onMouseUp)

      document.body.classList.add("cursor--tb")
    }
  }

  const onMouseUp = (e: MouseEvent | TouchEvent) => {
    document.exitPointerLock()

    if (!didMove.current) {
      inputRef.current!.focus()
    }

    didMove.current = false
    setIsActive(false)
    document.removeEventListener("mousemove", onDrag)
    document.removeEventListener("mouseup", onMouseUp)
    document.removeEventListener("touchmove", onDrag)
    document.removeEventListener("touchend", onMouseUp)
    document.body.classList.remove("cursor--tb")
  }

  const handleKeyDown = (e: KeyboardEvent) => {
    let direction = 1
    switch (e.code) {
      case "ArrowUp":
      case "ArrowDown":
        e.preventDefault()

        if (e.code === "ArrowUp") direction = 1
        if (e.code === "ArrowDown") direction = -1

        const multiplier = getMultiplier(e)
        const v = shiftValue(direction * multiplier)
        onChange(unscaleValue(v))
        break
      case "Escape":
      case "Enter":
        inputRef.current!.blur()
        break
    }
  }
  const increment = (direction: number, e: KeyboardEvent | MouseEvent) => {
    const multiplier = getMultiplier(e)
    onChange(unscaleValue(shiftValue(direction, multiplier)))
  }

  const handleInput = (e: any) => {
    let rawValue = e.target.value.replace(unit, "")
    let v = parseFloat(rawValue)

    if (!isNaN(v)) {
      v = clampValue(v)

      onChange(unscaleValue(v))
    }
  }

  const getMultiplier = (e: KeyboardEvent | MouseEvent) => {
    if (e.shiftKey) return steps.coarse
    if (e.altKey) return steps.fine
    else return steps.default
  }

  const onDrag = (e: MouseEvent | TouchEvent) => {
    didMove.current = true
    onChange(unscaleValue(shiftValue((e as any).movementY * -1, steps.fine)))
  }

  const shiftValue = (amount: number, stepSize: number = steps.default) => {
    let v = currentValue.current
    v ??= 0

    v += amount * stepSize
    v = clampValue(v)
    v = Math.round(v * 100) / 100

    currentValue.current = v
    return v
  }

  const clampValue = (v: number) => {
    v = min !== undefined ? Math.max(min, v) : v
    v = max !== undefined ? Math.min(max, v) : v
    return v
  }

  return (
    <div className={classNames(`NumericInput control fader`, { disabled, isActive })} ref={containerElement!}>
      <label onTouchStart={onMouseDown} onMouseDown={onMouseDown} id={`${id}-input`} htmlFor={id} className={`ControlTitle`}>
        <span className="short-label">{shortLabel}</span>
        <span className="full-label">{label}</span>
      </label>

      <input
        className={`draggable`}
        aria-labelledby={`${id}-input`}
        ref={inputRef}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        onBlur={handleInput}
        value={inputValue}
        inputMode="decimal"
        onChange={(e) => setInputValue(e.target.value)}
        {...{ disabled, min, max, step }}
      />
      <button onClick={(e) => increment(-1, e)}>−</button>
      <button onClick={(e) => increment(+1, e)}>+</button>
    </div>
  )
}
export default NumericInput
