import classNames from "classnames"
import React, { useState } from "react"
import SVG from "react-inlinesvg"
import { HeightTransition } from "./HeightTransition"

export const CSSPrintout = ({ value, label, successLabel }: { value: string; label: string; successLabel: string }) => {
  const [copied, setCopied] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const handleCopy = () => {
    navigator.clipboard.writeText(value).then(
      () => {
        setCopied(true)
        window.setTimeout(() => {
          setCopied(false)
        }, 3000)
      },
      () => {
        setCopied(false)
      }
    )
  }
  return (
    <div className={classNames("css-printout", {isOpen})}>
      <button className="summary" aria-expanded={isOpen} aria-controls="printout-details" onClick={() => setIsOpen(!isOpen)}>
        CSS Output
        <div className="arrow">
          <SVG src="/icons/right.svg" role="presentation" />
        </div>
      </button>
      <HeightTransition expanded={isOpen}>
        <div id="printout-details">
          <pre onClick={handleCopy}>
            <code>{value}</code>
          </pre>
        </div>
      </HeightTransition>
    </div>
  )
}
export default CSSPrintout
