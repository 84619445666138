import Image from "next/image"
import { FontSection } from "../components/FontSection"
interface IProps {
  src?: string
  alt?: string
  type?: "video" | "image"
  credit?: string
}

export const Cover = ({ src, alt, type, credit }: IProps) => {
  if (!src) return null

  if (type === "video") {
    return (
      <figure>
        <div className="cover-image">
          <video
            width="2400"
            height="2400"
            loop
            autoPlay
            muted
            aria-label={alt}
          >
            {["webm", "mp4"].map((ext) => (
              <source key={ext} src={`/videos/${src}.${ext}`} />
            ))}
          </video>
          {credit && (
            <FontSection>
              <figcaption className="credit">{credit}</figcaption>
            </FontSection>
          )}
        </div>
      </figure>
    )
  } else {
    return (
      <figure>
        <div className="cover-image">
          <Image src={src} alt={alt} placeholder="blur" />
          {credit && (
            <FontSection>
              <figcaption className="credit">{credit}</figcaption>
            </FontSection>
          )}
        </div>
      </figure>
    )
  }
}
